import '@fortawesome/fontawesome-free/js/all';
import 'bootstrap3/dist/js/bootstrap';
import 'bootstrap-select/dist/js/bootstrap-select';
import 'popper.js/dist/popper';
import ahoy from 'ahoy.js';
import imagesLoaded from 'imagesloaded/imagesloaded';

imagesLoaded.makeJQueryPlugin($);

$.ahoy = ahoy;

import './ifb_styles';
import './jquery.flexslider';
import './owl.carousel.min.js';
import './retina.min.js';
